<ace-loading-bar [autoMode]="false"></ace-loading-bar>

<div class="flex h-screen w-screen select-none flex-col bg-white">
  <!--Header-->
  <div class="border-tertiary-100 flex h-16 w-full flex-shrink-0 items-center justify-between border-b px-5">
    <!-- Logo -->
    <img
      routerLink="/"
      class="h-9 w-40 flex-shrink-0"
      width="159"
      height="36"
      ngSrc="https://core-dev.oss-cn-hangzhou.aliyuncs.com/static/images/wps/wps_%E6%85%A7%E6%8A%A5.png"
      [loaderParams]="{ original: true }"
      alt="wps"
    />
    <!-- user menu  -->
    <div class="z-49 bg-card relative flex flex-shrink-0 flex-grow items-center justify-end px-4 md:px-6 dark:bg-transparent print:hidden">
      <div class="flex flex-shrink-0 items-center space-x-0.5 pl-2 sm:space-x-2">
        <!--        <ace-fullscreen class="hidden md:block"></ace-fullscreen>-->
        <ace-user></ace-user>
      </div>
    </div>
  </div>
  <!--  content-->
  <div class="flex h-0 flex-grow gap-2 overflow-hidden">
    <div class="w-50 flex flex-col gap-1 p-2">
      <!-- Menus -->
      @for (menu of menus; track menu) {
        @if (menu.title) {
          <div class="text-tertiary-400 flex h-10 items-end px-4 py-1 text-sm font-normal">
            <span>{{ menu.title }}</span>
          </div>
        }
        @for (route of menu.routes; track route.link) {
          <div #routerLink="routerLinkActive" class="w-full px-1" [routerLink]="route.link" routerLinkActive>
            <div
              [class]="routerLink.isActive ? 'bg-primary-400 text-white' : 'hover:bg-primary-600 text-current hover:bg-opacity-10'"
              class="flex h-10 w-full cursor-pointer items-center gap-3 rounded-lg p-3 px-3"
            >
              <mat-icon
                class="icon-size-5 m-0"
                [class]="route.color ? '' : routerLink.isActive ? 'text-white' : 'text-current'"
                [style.color]="route.color"
                [svgIcon]="route.icon"
              ></mat-icon>
              <span class="text-base font-normal">{{ route.name | transloco }}</span>
            </div>
          </div>
        }
      }
    </div>
    <!-- Content -->
    <div class="h-full w-0 flex-grow overflow-hidden p-3">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
