import { CommonModule, NgOptimizedImage } from '@angular/common'
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { MatTooltip } from '@angular/material/tooltip'
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router'

import { TranslocoPipe } from '@ngneat/transloco'
import { Subject } from 'rxjs'

import { FullscreenComponent } from '@libs/ng-shared/components/fullscreen'
import { LoadingBarComponent } from '@libs/ng-shared/components/loading-bar'

import { NotificationComponent } from '#modules/home/components/notification/notification.component'
import { UserComponent } from '#modules/home/components/user/user.component'

@Component({
  selector: 'ace-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoadingBarComponent,
    MatIconButton,
    FullscreenComponent,
    MatIcon,
    MatTooltip,
    RouterLink,
    RouterLinkActive,
    UserComponent,
    NotificationComponent,
    TranslocoPipe,
    NgOptimizedImage
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnDestroy {
  unsubscribeAll = new Subject<null>()

  menus: Array<{ title: string; routes: Array<{ name: string; icon: string; link: string; active: boolean; color?: string }> }> = [
    {
      title: '',
      routes: [
        { name: '发现', icon: 'custom:menu-discovery', link: '/home/discovery', active: false },
        { name: '模版中心', icon: 'custom:menu-template', link: '/home/template', active: false }
      ]
    },
    {
      title: '工作台',
      routes: [
        { name: '我的空间', icon: 'custom:menu-mine', link: '/home/project', active: false },
        // { name: '我的收藏', icon: 'custom:menu-star', link: '/home/collect', active: false },
        { name: '回收站', icon: 'custom:menu-recycle', link: '/home/recycle', active: false }
      ]
    }
    // {
    //   title: 'AI创作',
    //   routes: [{ name: 'AI创作图文', icon: 'custom:menu-ai', link: '/home/ai', active: false, color: '#00FF94' }]
    // }
  ]

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }
}
