import { HttpBackend, HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'

import { Translation, TranslocoLoader } from '@ngneat/transloco'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class I18nService implements TranslocoLoader {
  private handler = inject(HttpBackend)

  private _httpClient: HttpClient

  constructor() {
    this._httpClient = new HttpClient(this.handler)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   *
   * @param lang
   */
  getTranslation(lang: string): Observable<Translation> {
    return this._httpClient.get<Translation>(`./assets/i18n/${lang}.json`)
  }
}
