import { Platform } from '@angular/cdk/platform'
import { DOCUMENT } from '@angular/common'
import { inject, Inject, Injectable } from '@angular/core'

export interface CookieOptions {
  path?: string
  domain?: string
  /**
   * Expiration time, `number` is seconds
   *
   * 过期时间，`number` 类型表示秒数
   */
  expires?: number | Date | string
  secure?: boolean
  HttpOnly?: boolean
  SameSite?: boolean | 'lax' | 'strict' | 'none'
}

/**
 * A set of simple Cookie manipulation classes.
 *
 * 一组简单的 Cookie 操作类。
 */
@Injectable({ providedIn: 'root' })
export class CookieService {
  private platform = inject(Platform)
  private doc = inject<Document>(DOCUMENT)

  private get _doc(): Document {
    return this.doc || document
  }

  private get _cookie(): string {
    return this.platform.isBrowser ? this._doc.cookie : ''
  }
  getAll(): { [key: string]: string } {
    const ret: { [key: string]: string } = {}
    const arr = this._cookie.split('; ')
    for (let i = 0; i < arr.length; i++) {
      const cookie = arr[i]
      const index = cookie.indexOf('=')
      if (index > 0) {
        const name = decodeURIComponent(cookie.substring(0, index))
        if (ret[name] == null) {
          ret[name] = decodeURIComponent(cookie.substring(index + 1))
        }
      }
    }
    return ret
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Get the value of given cookie `key`
  // -----------------------------------------------------------------------------------------------------
  get(key: string): string | undefined {
    return this.getAll()[key]
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Sets a value for given cookie key
  // -----------------------------------------------------------------------------------------------------
  put(key: string, value: string, options?: CookieOptions): void {
    if (!this.platform.isBrowser) {
      return
    }
    const opt = { path: '/', ...options }
    if (typeof opt.expires === 'number') {
      opt.expires = new Date(+new Date() + opt.expires * 1e3)
    }
    if (typeof opt.expires !== 'string') {
      opt.expires = opt.expires ? opt.expires.toUTCString() : ''
    }
    const optStr: {
      [key: string]: string | boolean | number | Date | undefined
    } = opt
    const attributes = Object.keys(optStr)
      .filter(k => optStr[k] && optStr[k] !== true)
      .map(k => `${k}=${(optStr[k] as string).split(';')[0]}`)
      .join(';')
    this._doc.cookie = `${encodeURIComponent(String(key))}=${encodeURIComponent(String(value))}${attributes ? `; ${attributes}` : ''}`
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Remove given cookie
  // -----------------------------------------------------------------------------------------------------
  remove(key: string, options?: CookieOptions): void {
    this.put(key, '', options)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Remove all cookies
  // -----------------------------------------------------------------------------------------------------
  removeAll(): void {
    this._doc.cookie = ''
  }
}
