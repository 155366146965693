import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, inject, isDevMode } from '@angular/core'
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { provideRouter, Router, TitleStrategy, withComponentInputBinding, withInMemoryScrolling, withViewTransitions } from '@angular/router'

import { provideTransloco, TranslocoService } from '@ngneat/transloco'
import { firstValueFrom } from 'rxjs'

import { EDITOR_API } from '@libs/editor'
import { authSimpleInterceptor } from '@libs/ng-core/auth'
import { provideIcons } from '@libs/ng-core/icons/icons.provide'
import { provideApp } from '@libs/ng-core/index'
import { authInterceptor } from '@libs/ng-core/interceptors/auth.interceptor'
import { I18nService } from '@libs/ng-core/services/i18n.service'

import { ApiService } from '#core/services/api.service'
import { AutoTranslateTitleStrategy } from '#core/strategies/auto-translate-title.strategy'
import { CustomMatPaginatorIntl } from '#core/strategies/custom-paginator-intl'

import { appRoutes } from './app.routes'

export function createAppConfig(): ApplicationConfig {
  const baseProviders: any[] = [
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authSimpleInterceptor, authInterceptor])),
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
        disabled: true,
        animation: {
          enterDuration: 300,
          exitDuration: 0
        }
      }
    },

    // Title Strategy
    {
      provide: TitleStrategy,
      useClass: AutoTranslateTitleStrategy
    },

    // 分页 Strategy
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },

    // Image Loaders
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        if (config.loaderParams?.['original'] === true) {
          return config.src
        } else {
          return `http://cdn.core.editorup.com/static/images/${config.src}`
        }
      }
    },

    // Transloco Config
    provideTransloco({
      config: {
        availableLangs: [
          {
            id: 'en',
            label: 'english'
          },
          {
            id: 'zh',
            label: 'chinese'
          }
        ],
        defaultLang: 'zh',
        fallbackLang: 'zh',
        reRenderOnLangChange: true,
        prodMode: !isDevMode()
      },
      loader: I18nService
    }),

    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      useFactory: () => {
        const translocoService = inject(TranslocoService)
        const defaultLang = translocoService.getDefaultLang()
        translocoService.setActiveLang(defaultLang)

        return () => firstValueFrom(translocoService.load(defaultLang))
      },
      // deps: [Sentry.TraceService],
      multi: true
    },
    // core config
    provideIcons(),

    provideApp({
      app: {
        auth: {
          storeType: 'localStorage',
          tokenSendPlace: 'header',
          loginUrl: '/auth/sign-in',
          tokenInvalidRedirect: true,
          tokenSendKey: 'Authorization',
          tokenSendTemplate: 'Bearer ${token}',
          tokenExpiredOffset: 10,
          storeKey: '_token',
          ignores: [/\/login/, /\/register/, /\/refresh/, /assets\//],
          refreshTime: 3000,
          refreshOffset: 6000
        },
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px'
        }
      }
    }),
    provideRouter(appRoutes, withComponentInputBinding(), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }), withViewTransitions()),
    {
      provide: EDITOR_API,
      useClass: ApiService
    }
  ]

  // if (!isDevMode()) {
  //   // Sentry configuration for production mode only
  //   baseProviders.push(
  //     {
  //       provide: ErrorHandler,
  //       useValue: Sentry.createErrorHandler({
  //         showDialog: true
  //       })
  //     },
  //     {
  //       provide: Sentry.TraceService,
  //       deps: [Router]
  //     }
  //   )
  // }

  return { providers: baseProviders }
}
export const appConfig: ApplicationConfig = createAppConfig()
