import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, Provider } from '@angular/core'
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MAT_SELECT_CONFIG } from '@angular/material/select'

import { AppConfig } from '@libs/ng-shared/services/config'
import { APP_CONFIG } from '@libs/ng-shared/services/config/config.constants'
import { ConfirmationService } from '@libs/ng-shared/services/confirmation'
import { loadingInterceptor, LoadingService } from '@libs/ng-shared/services/loading'
import { MediaWatcherService } from '@libs/ng-shared/services/media-watcher'
import { PlatformService } from '@libs/ng-shared/services/platform'
import { SplashScreenService } from '@libs/ng-shared/services/splash-screen'
import { CookieService } from '@libs/ng-shared/services/storage'
import { UtilsService } from '@libs/ng-shared/services/utils'

import { AUTH_STORE_TOKEN, CookieStore, LocalStore, MemoryStore, SessionStore } from './auth'

export interface ProviderConfig {
  app: AppConfig
}

/**
 * App provider
 */
export const provideApp = (config: ProviderConfig): Array<Provider | EnvironmentProviders> => {
  // Base providers
  const providers: Array<Provider | EnvironmentProviders> = [
    {
      // Disable 'theme' sanity check
      provide: MATERIAL_SANITY_CHECKS,
      useValue: {
        doctype: true,
        theme: false,
        version: true
      }
    },
    {
      // Use the 'fill' appearance on Angular Material form fields by default
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        overlayPanelClass: 'ace-select-overlay'
      }
    },
    {
      provide: APP_CONFIG,
      useValue: config?.app ?? {}
    },

    importProvidersFrom(MatDialogModule),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(ConfirmationService),
      multi: true
    },

    provideHttpClient(withInterceptors([loadingInterceptor])),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(CookieService),
      multi: true
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(LoadingService),
      multi: true
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(MediaWatcherService),
      multi: true
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(PlatformService),
      multi: true
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(SplashScreenService),
      multi: true
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(UtilsService),
      multi: true
    }
  ]

  // choose auth type and store
  switch (config.app.auth.storeType) {
    case 'cookie':
      providers.push({
        provide: AUTH_STORE_TOKEN,
        useClass: CookieStore,
        deps: [CookieService]
      })
      break
    case 'memory':
      providers.push({
        provide: AUTH_STORE_TOKEN,
        useClass: MemoryStore
      })
      break
    case 'session':
      providers.push({
        provide: AUTH_STORE_TOKEN,
        useClass: SessionStore
      })
      break
    default:
      providers.push({
        provide: AUTH_STORE_TOKEN,
        useClass: LocalStore
      })
  }

  return providers
}
