/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse, HttpHandlerFn, HttpHeaders, HttpInterceptorFn, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http'
import { APP_INITIALIZER, inject, Injector, Provider } from '@angular/core'
import { Router } from '@angular/router'

import { TRANSLOCO_CONFIG } from '@ngneat/transloco'
import { HotToastService } from '@ngxpert/hot-toast'
import { BehaviorSubject, catchError, filter, mergeMap, Observable, of, switchMap, take, throwError } from 'rxjs'

import { env } from '@libs/ng-env'

import { AUTH_SERVICE_TOKEN } from '../auth'
import { CUSTOM_ERROR_MSG, IGNORE_BASE_URL, RAW_BODY } from '../constants'

interface ReThrowHttpError {
  body: any
  _throw: true
}

let refreshToking = false
const refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null)

function handleData(injector: Injector, ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandlerFn): Observable<any> {
  // 业务处理：一些通用操作
  switch (ev.status) {
    case 200:
    case 201:
    case 304:
      //  错误内容：{ code: 1, msg: '非法参数' }
      //  正确内容：{ code: 0, msg: 'ok', data: {  } }
      if (ev instanceof HttpResponse) {
        const body = ev.body
        // 预知错误，拥有错误码
        if (body && body.code !== 0) {
          if (req.context.get(CUSTOM_ERROR_MSG)) {
            // 明确错误由调用者处理
            return throwError(() => ({ body, _throw: true }) as ReThrowHttpError)
          } else {
            // 统一处理错误信息
            injector.get(HotToastService).warning(body.msg)
            return of({})
            // 或自行处理错误信息
            // return of(ev)
          }
        } else {
          // 返回原始返回体
          if (req.context.get(RAW_BODY) || ev.body instanceof Blob) {
            return of(ev)
          }
          // 重新修改 `body` 内容为 `data` 内容，对于绝大多数场景已经无须再关心业务状态码
          return of(new HttpResponse({ ...ev, body: body.data } as any))
          // // 或者依然保持完整的格式
          // return of(ev)
        }
      }
      break
    case 401:
      if (env.api.refreshTokenEnabled && env.api.refreshTokenType === 're-request') {
        return tryRefreshToken(injector, ev, req, next)
      }
      toLogin(injector)
      break
    case 403:
    case 404:
    case 500:
      goTo(injector, `/exception/${ev.status}?url=${req.urlWithParams}`)
      break
    default:
      if (ev instanceof HttpErrorResponse) {
        console.warn('未可知错误，大部分是由于后端不支持跨域CORS或无效配置引起的跨域问题', ev)
      }
      break
  }
  if (ev instanceof HttpErrorResponse) {
    return throwError(() => ev)
  } else if ((ev as unknown as ReThrowHttpError)._throw) {
    return throwError(() => (ev as unknown as ReThrowHttpError).body)
  } else {
    return of(ev)
  }
}

function goTo(injector: Injector, url: string): void {
  setTimeout(() => injector.get(Router).navigateByUrl(url))
}

function toLogin(injector: Injector): void {
  goTo(injector, injector.get(AUTH_SERVICE_TOKEN).loginUrl as string)
}

function getAdditionalHeaders(headers?: HttpHeaders): {
  [name: string]: string
} {
  const res: { [name: string]: string } = {}
  const lang = inject(TRANSLOCO_CONFIG).defaultLang
  if (!headers?.has('Accept-Language') && lang) {
    res['Accept-Language'] = lang
  }

  return res
}

/**
 * 重新附加新 Token 信息
 *
 * > 由于已经发起的请求，不会再走一遍 `@delon/auth` 因此需要结合业务情况重新附加新的 Token
 */
function reAttachToken(injector: Injector, req: HttpRequest<any>): HttpRequest<any> {
  const token = injector.get(AUTH_SERVICE_TOKEN).get()?.token
  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`
    }
  })
}

function refreshTokenRequest(injector: Injector): Observable<any> {
  const model = injector.get(AUTH_SERVICE_TOKEN).get()
  return injector.get(HttpClient).get(`/api/v1/auth/refresh`, {
    headers: { 'Refresh-Token': model?.['refreshToken'] || '' }
  })
}

/** 保证重新发起的请求依然走拦截器逻辑 */
function resendRequest(injector: Injector, req: HttpRequest<any>, next: HttpHandlerFn): Observable<any> {
  const newReq = reAttachToken(injector, req)
  return next(newReq).pipe(
    mergeMap(newEv => {
      if (newEv instanceof HttpResponseBase) {
        return handleData(injector, newEv, newReq, next)
      }
      return of(newEv)
    })
  )
}

/**
 * 方式一：使用 401 重新刷新 Token
 */
function tryRefreshToken(injector: Injector, ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandlerFn): Observable<any> {
  // 1、若请求为刷新Token请求，表示来自刷新Token可以直接跳转登录页
  if ([`/api/v1/auth/refresh`].some(url => req.url.includes(url))) {
    toLogin(injector)
    return throwError(() => ev)
  }
  // 2、如果 `refreshToking` 为 `true` 表示已经在请求刷新 Token 中，后续所有请求转入等待状态，直至结果返回后再重新发起请求
  if (refreshToking) {
    return refreshToken$.pipe(
      filter(v => !!v),
      take(1),
      // switchMap(() => next(reAttachToken(injector, req)))
      switchMap(() => resendRequest(injector, req, next))
    )
  }
  // 3、尝试调用刷新 Token
  refreshToking = true
  refreshToken$.next(null)

  return refreshTokenRequest(injector).pipe(
    switchMap(res => {
      // 通知后续请求继续执行
      refreshToking = false
      refreshToken$.next(res)
      // 重新保存新 token
      injector.get(AUTH_SERVICE_TOKEN).set(res)
      // 重新发起请求
      // return next(reAttachToken(injector, req))
      return resendRequest(injector, req, next)
    }),
    catchError(err => {
      refreshToking = false
      toLogin(injector)
      return throwError(() => err)
    })
  )
}

// 方式二：可选使用 `@shared/auth` 的 `refresh` 接口，在 `app.config.ts` 中注册 `provideBindAuthRefresh`
export function provideBindAuthRefresh(): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: (injector: Injector) => () => buildAuthRefresh(injector),
      deps: [Injector],
      multi: true
    }
  ]
}

function buildAuthRefresh(injector: Injector) {
  const tokenSrv = injector.get(AUTH_SERVICE_TOKEN)
  tokenSrv.refresh
    .pipe(
      filter(() => !refreshToking),
      switchMap(res => {
        refreshToking = true
        return refreshTokenRequest(injector)
      })
    )
    .subscribe({
      next: res => {
        // TODO: Mock expired value
        res.expired = +new Date() + 1000 * 60 * 5
        refreshToking = false
        tokenSrv.set(res)
      },
      error: () => toLogin(injector)
    })
}

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  // 统一加上服务端前缀
  let url = req.url

  // TODO: 临时处理，后续需要优化
  if (url.startsWith('assets') || url.startsWith('/assets')) {
    return next(req)
  }

  if (!req.context.get(IGNORE_BASE_URL) && !url.startsWith('https://') && !url.startsWith('http://')) {
    const { baseUrl } = env.api
    url = baseUrl + (baseUrl.endsWith('/') && url.startsWith('/') ? url.substring(1) : url)
  }

  const newReq = req.clone({
    url,
    setHeaders: getAdditionalHeaders(req.headers)
  })
  const injector = inject(Injector)

  return next(newReq).pipe(
    mergeMap(ev => {
      // 允许统一对请求错误处理
      if (ev instanceof HttpResponseBase) {
        return handleData(injector, ev, newReq, next)
      }
      // 若一切都正常，则后续操作
      return of(ev)
    }),
    catchError((err: HttpErrorResponse) => {
      return handleData(injector, err, newReq, next)
    })
  )
}
