import { inject } from '@angular/core'
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router'

import { of } from 'rxjs'

import { AUTH_SERVICE_TOKEN, ITokenService, JWTTokenModel } from '@libs/ng-core/auth'

import { AppStore } from '#core/store/app.store'

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
  const appStore = inject(AppStore)
  const tokenService = inject<ITokenService>(AUTH_SERVICE_TOKEN)
  const router: Router = inject(Router)

  // 默认没有登陆
  let authenticated = false

  // TODO: 本身不仅只是检查是否存在，更应该去识别refreshToken的有效性，目前简化
  const jwt = tokenService.get(JWTTokenModel) as JWTTokenModel
  if (jwt.token) {
    authenticated = true
  }

  if (authenticated) {
    return of(true)
  } else {
    appStore.logout()
    const redirectURL = state.url === '/auth/sign-out' ? '' : `redirectURL=${state.url}`
    const urlTree = router.parseUrl(`auth/sign-in?${redirectURL}`)
    return of(urlTree)
  }
}
