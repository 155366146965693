import { Routes } from '@angular/router'

import { loadEditor } from '@libs/editor'

// import { ReplaceTitleResolver } from '@libs/ng-core/resolvers/replace-title.resolver'

import { AuthGuard } from '#core/guards/auth.guard'
import { NoAuthGuard } from '#core/guards/no-auth.guard'
import { AuthComponent } from '#modules/auth/auth.component'
import { HomeComponent } from '#modules/home/home.component'

// import { appInitDataResolver } from './app.resolvers'

export const appRoutes: Routes = [
  // Redirect empty path to '/home/project'
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home/discovery'
  },

  // Redirect signed-in user to the '/dashboards/project'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  // signed-in-redirect will be in magic link
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home/project' },

  // Auth routes for guests
  {
    path: 'auth',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: AuthComponent,
    loadChildren: () => import('./modules/auth/auth.routes')
  },

  // Home routes
  {
    path: 'home',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: HomeComponent,
    loadChildren: () => import('./modules/home/home.routes')
  },

  // Workspace routes
  {
    path: 'workspace',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    // component: WorkspaceContainerComponent,
    // resolve: {
    //   projectList: projectListResolver
    // },
    loadChildren: () => loadEditor()
    // children: [
    //   {
    //     path: ':projectId',
    //     component: WorkspaceComponent,
    //     resolve: {
    //       projectDB: projectDBResolver,
    //       project: projectDataResolver
    //     },
    //     title: ReplaceTitleResolver
    //   }
    // ]
  },
  // {
  //   path: 'workspace/:projectId',
  //   canActivate: [AuthGuard, canActiveProjectFn],
  //   providers: [{ provide: APP_DB, useFactory: getAppDBFactory, deps: [ActivatedRoute] }, ProjectCollectionService, ProjectListService],
  //   resolve: {
  //     // initialData: appInitDataResolver,
  //     appDB: appDbResolver,
  //     project: projectDataResolver,
  //     projectDB: projectDbResolver
  //   },
  //   component: WorkspaceComponent,
  //   title: ReplaceTitleResolver
  // },

  // 404 & 500
  {
    path: '404-not-found',
    loadComponent: () => import('./modules/exception/pages/error-404/error-404.component').then(m => m.Error404Component)
  },

  // Catch all
  { path: '**', redirectTo: '404-not-found' }
]
