import { inject } from '@angular/core'
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router'

import { of } from 'rxjs'

import { AUTH_SERVICE_TOKEN, ITokenService, JWTTokenModel } from '@libs/ng-core/auth'
import { CheckJwt } from '@libs/ng-core/auth/token/helper'

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = () => {
  const tokenService = inject<ITokenService>(AUTH_SERVICE_TOKEN)
  const router: Router = inject(Router)

  let authenticated = true
  const token = tokenService.get(JWTTokenModel) as JWTTokenModel

  if (!token || !CheckJwt(token, 0)) {
    authenticated = false
  }

  if (authenticated) {
    return of(router.parseUrl(''))
  } else {
    return of(true)
  }
}
