// import { isDevMode } from '@angular/core'
import { bootstrapApplication } from '@angular/platform-browser'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { RxDatabase } from 'rxdb'
// import * as Sentry from '@sentry/angular'
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle'

import { AppComponent } from './app/app.component'
import { appConfig } from './app/app.config'

// import { AppModule } from './app/app.module'

// if (!isDevMode()) {
//   Sentry.init({
//     dsn: 'https://28f8f48984d8d9862634b224b0259461@o4507820983320576.ingest.us.sentry.io/4507820985942016',
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration({
//         // Additional SDK configuration goes in here, for example:
//         maskAllText: false,
//         blockAllMedia: false,
//         networkDetailAllowUrls: [/^https:\/\/api\.editorup\.com/],
//         networkRequestHeaders: ['Authorization'],
//         networkResponseHeaders: ['X-Custom-Header']
//       })
//     ],
//     beforeSend(event, hint) {
//       // disable crash report modal
//       return null
//       // Check if it is an exception, and if so, show the report dialog
//       // if (event.exception && event.event_id) {
//       //   Sentry.showReportDialog({ eventId: event.event_id })
//       // }
//       // return event
//     },
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', /^https:\/\/api\.editorup\.com/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   })
// }

// register Swiper custom elements
register()

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err))
